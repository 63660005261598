require('dot-env');

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const USER_ROLE = {
    SUDOER: 'sudoer',
    EXPERT: 'expert',
    AUDITOR: 'auditor',
    ADMIN: 'admin',
    CUSTOM: 'custom',
};

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:10002/api' : process.env.REACT_APP_BASE_URL;

export const REACT_APP_CLIENT_DASHBOARD_BASE_URI = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/auth' : process.env.REACT_APP_CLIENT_DASHBOARD_BASE_URI;
export const ROLES = {
    SUDOER: '',
};
export const AccessRoutes = [
    'dashboard',
    'frequency',
    'ventilation',
    'cooling',
    'versionHistory',
    'heatPump',
    'evCharging',
    'peakShavingAndAlert',
    'loadShifting',
    'powerManagement',
    'gridManagerOnline',
    'carbonEmission',
    'datahub',
    'outOfHours',
    'nordpool',
    'fcrPricing',
    'realTimeMarket',
    'vehicle',
    'eConomics',
    'solar',
    'bess',
    'fan',
    'lighting',
    'decooling',
    'deicing',
    'oven',
    'ups',
    'generator',
    'generic',
    'boiler',
    'electricboiler',
];
export const FCR_Aggrements_Days = [
    {
        title: 'monday',
        enabled: 'activeDays.monday.enabled',
        startHour: 'activeDays.monday.startHour',
        endHour: 'activeDays.monday.endHour',
        enabled1: 'activeDays.monday.enabled1',
        startHour1: 'activeDays.monday.startHour1',
        endHour1: 'activeDays.monday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'tuesday',
        enabled: 'activeDays.tuesday.enabled',
        startHour: 'activeDays.tuesday.startHour',
        endHour: 'activeDays.tuesday.endHour',
        enabled1: 'activeDays.tuesday.enabled1',
        startHour1: 'activeDays.tuesday.startHour1',
        endHour1: 'activeDays.tuesday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'wednesday',
        enabled: 'activeDays.wednesday.enabled',
        startHour: 'activeDays.wednesday.startHour',
        endHour: 'activeDays.wednesday.endHour',
        enabled1: 'activeDays.wednesday.enabled1',
        startHour1: 'activeDays.wednesday.startHour1',
        endHour1: 'activeDays.wednesday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'thursday',
        enabled: 'activeDays.thursday.enabled',
        startHour: 'activeDays.thursday.startHour',
        endHour: 'activeDays.thursday.endHour',
        enabled1: 'activeDays.thursday.enabled1',
        startHour1: 'activeDays.thursday.startHour1',
        endHour1: 'activeDays.thursday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'friday',
        enabled: 'activeDays.friday.enabled',
        startHour: 'activeDays.friday.startHour',
        endHour: 'activeDays.friday.endHour',
        enabled1: 'activeDays.friday.enabled1',
        startHour1: 'activeDays.friday.startHour1',
        endHour1: 'activeDays.friday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'saturday',
        enabled: 'activeDays.saturday.enabled',
        startHour: 'activeDays.saturday.startHour',
        endHour: 'activeDays.saturday.endHour',
        enabled1: 'activeDays.saturday.enabled1',
        startHour1: 'activeDays.saturday.startHour1',
        endHour1: 'activeDays.saturday.endHour1',
        enabledProperty: 'activeDays',
    },
    {
        title: 'sunday',
        enabled: 'activeDays.sunday.enabled',
        startHour: 'activeDays.sunday.startHour',
        endHour: 'activeDays.sunday.endHour',
        enabled1: 'activeDays.sunday.enabled1',
        startHour1: 'activeDays.sunday.startHour1',
        endHour1: 'activeDays.sunday.endHour1',
        enabledProperty: 'activeDays',
    },
];

export const THREE_STATE_TOGGLE_LABELS = {
    left: {
        title: 'Disable',
        value: 'left',
    },
    center: {
        title: 'Default',
        value: 'center',
    },
    right: {
        title: 'Enable',
        value: 'right',
    },
};


export const VENTILATION_CONFIG = [
    {
        title: 'p200',

        name: 'ventillation.p200',
    },
    {
        title: 'p190',

        name: 'ventillation.p190',
    },
    {
        title: 'p180',

        name: 'ventillation.p180',
    },
    {
        title: 'p170',

        name: 'ventillation.p170',
    },
    {
        title: 'p160',

        name: 'ventillation.p160',
    },
    {
        title: 'p150',

        name: 'ventillation.p150',
    },
    {
        title: 'p140',

        name: 'ventillation.p140',
    },
    {
        title: 'p130',

        name: 'ventillation.p130',
    },
    {
        title: 'p120',

        name: 'ventillation.p120',
    },
    {
        title: 'p110',

        name: 'ventillation.p110',
    },
    {
        title: 'p100a',

        name: 'ventillation.p100a',
    },
    {
        title: 'p100',

        name: 'ventillation.p100',
    },
    {
        title: 'p90',

        name: 'ventillation.p90',
    },
    {
        title: 'p80',

        name: 'ventillation.p80',
    },
    {
        title: 'p70',

        name: 'ventillation.p70',
    },
    {
        title: 'p60',

        name: 'ventillation.p60',
    },
    {
        title: 'p50',

        name: 'ventillation.p50',
    },

    {
        title: 'p40',

        name: 'ventillation.p40',
    },
    {
        title: 'p30',

        name: 'ventillation.p30',
    },
    {
        title: 'p20',

        name: 'ventillation.p20',
    },
    {
        title: 'p10',

        name: 'ventillation.p10',
    },
    {
        title: 'p0',

        name: 'ventillation.p0',
    },
];

export const BIDDING_MARKETS = ['FCR', 'FFR', 'FCR-D-INC', 'FCR-N', 'DC-PLANNER'];

export const COUNTRY_CODES = [
    {
        name: 'Denmark',
        dial_code: '+45',
        code: 'DK',
    },
    {
        name: 'India',
        dial_code: '+91',
        code: 'IN',
    },
    {
        name: 'Afghanistan',
        dial_code: '+93',
        code: 'AF',
    },
    {
        name: 'Aland Islands',
        dial_code: '+358',
        code: 'AX',
    },
    {
        name: 'Albania',
        dial_code: '+355',
        code: 'AL',
    },
    {
        name: 'Algeria',
        dial_code: '+213',
        code: 'DZ',
    },
    {
        name: 'AmericanSamoa',
        dial_code: '+1684',
        code: 'AS',
    },
    {
        name: 'Andorra',
        dial_code: '+376',
        code: 'AD',
    },
    {
        name: 'Angola',
        dial_code: '+244',
        code: 'AO',
    },
    {
        name: 'Anguilla',
        dial_code: '+1264',
        code: 'AI',
    },
    {
        name: 'Antarctica',
        dial_code: '+672',
        code: 'AQ',
    },
    {
        name: 'Antigua and Barbuda',
        dial_code: '+1268',
        code: 'AG',
    },
    {
        name: 'Argentina',
        dial_code: '+54',
        code: 'AR',
    },
    {
        name: 'Armenia',
        dial_code: '+374',
        code: 'AM',
    },
    {
        name: 'Aruba',
        dial_code: '+297',
        code: 'AW',
    },
    {
        name: 'Australia',
        dial_code: '+61',
        code: 'AU',
    },
    {
        name: 'Austria',
        dial_code: '+43',
        code: 'AT',
    },
    {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: 'AZ',
    },
    {
        name: 'Bahamas',
        dial_code: '+1242',
        code: 'BS',
    },
    {
        name: 'Bahrain',
        dial_code: '+973',
        code: 'BH',
    },
    {
        name: 'Bangladesh',
        dial_code: '+880',
        code: 'BD',
    },
    {
        name: 'Barbados',
        dial_code: '+1246',
        code: 'BB',
    },
    {
        name: 'Belarus',
        dial_code: '+375',
        code: 'BY',
    },
    {
        name: 'Belgium',
        dial_code: '+32',
        code: 'BE',
    },
    {
        name: 'Belize',
        dial_code: '+501',
        code: 'BZ',
    },
    {
        name: 'Benin',
        dial_code: '+229',
        code: 'BJ',
    },
    {
        name: 'Bermuda',
        dial_code: '+1441',
        code: 'BM',
    },
    {
        name: 'Bhutan',
        dial_code: '+975',
        code: 'BT',
    },
    {
        name: 'Bolivia, Plurinational State of',
        dial_code: '+591',
        code: 'BO',
    },
    {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: 'BA',
    },
    {
        name: 'Botswana',
        dial_code: '+267',
        code: 'BW',
    },
    {
        name: 'Brazil',
        dial_code: '+55',
        code: 'BR',
    },
    {
        name: 'British Indian Ocean Territory',
        dial_code: '+246',
        code: 'IO',
    },
    {
        name: 'Brunei Darussalam',
        dial_code: '+673',
        code: 'BN',
    },
    {
        name: 'Bulgaria',
        dial_code: '+359',
        code: 'BG',
    },
    {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: 'BF',
    },
    {
        name: 'Burundi',
        dial_code: '+257',
        code: 'BI',
    },
    {
        name: 'Cambodia',
        dial_code: '+855',
        code: 'KH',
    },
    {
        name: 'Cameroon',
        dial_code: '+237',
        code: 'CM',
    },
    {
        name: 'Canada',
        dial_code: '+1',
        code: 'CA',
    },
    {
        name: 'Cape Verde',
        dial_code: '+238',
        code: 'CV',
    },
    {
        name: 'Cayman Islands',
        dial_code: '+ 345',
        code: 'KY',
    },
    {
        name: 'Central African Republic',
        dial_code: '+236',
        code: 'CF',
    },
    {
        name: 'Chad',
        dial_code: '+235',
        code: 'TD',
    },
    {
        name: 'Chile',
        dial_code: '+56',
        code: 'CL',
    },
    {
        name: 'China',
        dial_code: '+86',
        code: 'CN',
    },
    {
        name: 'Christmas Island',
        dial_code: '+61',
        code: 'CX',
    },
    {
        name: 'Cocos (Keeling) Islands',
        dial_code: '+61',
        code: 'CC',
    },
    {
        name: 'Colombia',
        dial_code: '+57',
        code: 'CO',
    },
    {
        name: 'Comoros',
        dial_code: '+269',
        code: 'KM',
    },
    {
        name: 'Congo',
        dial_code: '+242',
        code: 'CG',
    },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        dial_code: '+243',
        code: 'CD',
    },
    {
        name: 'Cook Islands',
        dial_code: '+682',
        code: 'CK',
    },
    {
        name: 'Costa Rica',
        dial_code: '+506',
        code: 'CR',
    },
    {
        name: "Cote d'Ivoire",
        dial_code: '+225',
        code: 'CI',
    },
    {
        name: 'Croatia',
        dial_code: '+385',
        code: 'HR',
    },
    {
        name: 'Cuba',
        dial_code: '+53',
        code: 'CU',
    },
    {
        name: 'Cyprus',
        dial_code: '+357',
        code: 'CY',
    },
    {
        name: 'Czech Republic',
        dial_code: '+420',
        code: 'CZ',
    },
    {
        name: 'Djibouti',
        dial_code: '+253',
        code: 'DJ',
    },
    {
        name: 'Dominica',
        dial_code: '+1767',
        code: 'DM',
    },
    {
        name: 'Dominican Republic',
        dial_code: '+1849',
        code: 'DO',
    },
    {
        name: 'Ecuador',
        dial_code: '+593',
        code: 'EC',
    },
    {
        name: 'Egypt',
        dial_code: '+20',
        code: 'EG',
    },
    {
        name: 'El Salvador',
        dial_code: '+503',
        code: 'SV',
    },
    {
        name: 'Equatorial Guinea',
        dial_code: '+240',
        code: 'GQ',
    },
    {
        name: 'Eritrea',
        dial_code: '+291',
        code: 'ER',
    },
    {
        name: 'Estonia',
        dial_code: '+372',
        code: 'EE',
    },
    {
        name: 'Ethiopia',
        dial_code: '+251',
        code: 'ET',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        dial_code: '+500',
        code: 'FK',
    },
    {
        name: 'Faroe Islands',
        dial_code: '+298',
        code: 'FO',
    },
    {
        name: 'Fiji',
        dial_code: '+679',
        code: 'FJ',
    },
    {
        name: 'Finland',
        dial_code: '+358',
        code: 'FI',
    },
    {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
    },
    {
        name: 'French Guiana',
        dial_code: '+594',
        code: 'GF',
    },
    {
        name: 'French Polynesia',
        dial_code: '+689',
        code: 'PF',
    },
    {
        name: 'Gabon',
        dial_code: '+241',
        code: 'GA',
    },
    {
        name: 'Gambia',
        dial_code: '+220',
        code: 'GM',
    },
    {
        name: 'Georgia',
        dial_code: '+995',
        code: 'GE',
    },
    {
        name: 'Germany',
        dial_code: '+49',
        code: 'DE',
    },
    {
        name: 'Ghana',
        dial_code: '+233',
        code: 'GH',
    },
    {
        name: 'Gibraltar',
        dial_code: '+350',
        code: 'GI',
    },
    {
        name: 'Greece',
        dial_code: '+30',
        code: 'GR',
    },
    {
        name: 'Greenland',
        dial_code: '+299',
        code: 'GL',
    },
    {
        name: 'Grenada',
        dial_code: '+1473',
        code: 'GD',
    },
    {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: 'GP',
    },
    {
        name: 'Guam',
        dial_code: '+1671',
        code: 'GU',
    },
    {
        name: 'Guatemala',
        dial_code: '+502',
        code: 'GT',
    },
    {
        name: 'Guernsey',
        dial_code: '+44',
        code: 'GG',
    },
    {
        name: 'Guinea',
        dial_code: '+224',
        code: 'GN',
    },
    {
        name: 'Guinea-Bissau',
        dial_code: '+245',
        code: 'GW',
    },
    {
        name: 'Guyana',
        dial_code: '+595',
        code: 'GY',
    },
    {
        name: 'Haiti',
        dial_code: '+509',
        code: 'HT',
    },
    {
        name: 'Holy See (Vatican City State)',
        dial_code: '+379',
        code: 'VA',
    },
    {
        name: 'Honduras',
        dial_code: '+504',
        code: 'HN',
    },
    {
        name: 'Hong Kong',
        dial_code: '+852',
        code: 'HK',
    },
    {
        name: 'Hungary',
        dial_code: '+36',
        code: 'HU',
    },
    {
        name: 'Iceland',
        dial_code: '+354',
        code: 'IS',
    },
    {
        name: 'Indonesia',
        dial_code: '+62',
        code: 'ID',
    },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        dial_code: '+98',
        code: 'IR',
    },
    {
        name: 'Iraq',
        dial_code: '+964',
        code: 'IQ',
    },
    {
        name: 'Ireland',
        dial_code: '+353',
        code: 'IE',
    },
    {
        name: 'Isle of Man',
        dial_code: '+44',
        code: 'IM',
    },
    {
        name: 'Israel',
        dial_code: '+972',
        code: 'IL',
    },
    {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT',
    },
    {
        name: 'Jamaica',
        dial_code: '+1876',
        code: 'JM',
    },
    {
        name: 'Japan',
        dial_code: '+81',
        code: 'JP',
    },
    {
        name: 'Jersey',
        dial_code: '+44',
        code: 'JE',
    },
    {
        name: 'Jordan',
        dial_code: '+962',
        code: 'JO',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+77',
        code: 'KZ',
    },
    {
        name: 'Kenya',
        dial_code: '+254',
        code: 'KE',
    },
    {
        name: 'Kiribati',
        dial_code: '+686',
        code: 'KI',
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: '+850',
        code: 'KP',
    },
    {
        name: 'Korea, Republic of South Korea',
        dial_code: '+82',
        code: 'KR',
    },
    {
        name: 'Kuwait',
        dial_code: '+965',
        code: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: 'KG',
    },
    {
        name: 'Laos',
        dial_code: '+856',
        code: 'LA',
    },
    {
        name: 'Latvia',
        dial_code: '+371',
        code: 'LV',
    },
    {
        name: 'Lebanon',
        dial_code: '+961',
        code: 'LB',
    },
    {
        name: 'Lesotho',
        dial_code: '+266',
        code: 'LS',
    },
    {
        name: 'Liberia',
        dial_code: '+231',
        code: 'LR',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        dial_code: '+218',
        code: 'LY',
    },
    {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: 'LI',
    },
    {
        name: 'Lithuania',
        dial_code: '+370',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
    },
    {
        name: 'Macao',
        dial_code: '+853',
        code: 'MO',
    },
    {
        name: 'Macedonia',
        dial_code: '+389',
        code: 'MK',
    },
    {
        name: 'Madagascar',
        dial_code: '+261',
        code: 'MG',
    },
    {
        name: 'Malawi',
        dial_code: '+265',
        code: 'MW',
    },
    {
        name: 'Malaysia',
        dial_code: '+60',
        code: 'MY',
    },
    {
        name: 'Maldives',
        dial_code: '+960',
        code: 'MV',
    },
    {
        name: 'Mali',
        dial_code: '+223',
        code: 'ML',
    },
    {
        name: 'Malta',
        dial_code: '+356',
        code: 'MT',
    },
    {
        name: 'Marshall Islands',
        dial_code: '+692',
        code: 'MH',
    },
    {
        name: 'Martinique',
        dial_code: '+596',
        code: 'MQ',
    },
    {
        name: 'Mauritania',
        dial_code: '+222',
        code: 'MR',
    },
    {
        name: 'Mauritius',
        dial_code: '+230',
        code: 'MU',
    },
    {
        name: 'Mayotte',
        dial_code: '+262',
        code: 'YT',
    },
    {
        name: 'Mexico',
        dial_code: '+52',
        code: 'MX',
    },
    {
        name: 'Micronesia, Federated States of Micronesia',
        dial_code: '+691',
        code: 'FM',
    },
    {
        name: 'Moldova',
        dial_code: '+373',
        code: 'MD',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
    },
    {
        name: 'Mongolia',
        dial_code: '+976',
        code: 'MN',
    },
    {
        name: 'Montenegro',
        dial_code: '+382',
        code: 'ME',
    },
    {
        name: 'Montserrat',
        dial_code: '+1664',
        code: 'MS',
    },
    {
        name: 'Morocco',
        dial_code: '+212',
        code: 'MA',
    },
    {
        name: 'Mozambique',
        dial_code: '+258',
        code: 'MZ',
    },
    {
        name: 'Myanmar',
        dial_code: '+95',
        code: 'MM',
    },
    {
        name: 'Namibia',
        dial_code: '+264',
        code: 'NA',
    },
    {
        name: 'Nauru',
        dial_code: '+674',
        code: 'NR',
    },
    {
        name: 'Nepal',
        dial_code: '+977',
        code: 'NP',
    },
    {
        name: 'Netherlands',
        dial_code: '+31',
        code: 'NL',
    },
    {
        name: 'Netherlands Antilles',
        dial_code: '+599',
        code: 'AN',
    },
    {
        name: 'New Caledonia',
        dial_code: '+687',
        code: 'NC',
    },
    {
        name: 'New Zealand',
        dial_code: '+64',
        code: 'NZ',
    },
    {
        name: 'Nicaragua',
        dial_code: '+505',
        code: 'NI',
    },
    {
        name: 'Niger',
        dial_code: '+227',
        code: 'NE',
    },
    {
        name: 'Nigeria',
        dial_code: '+234',
        code: 'NG',
    },
    {
        name: 'Niue',
        dial_code: '+683',
        code: 'NU',
    },
    {
        name: 'Norfolk Island',
        dial_code: '+672',
        code: 'NF',
    },
    {
        name: 'Northern Mariana Islands',
        dial_code: '+1670',
        code: 'MP',
    },
    {
        name: 'Norway',
        dial_code: '+47',
        code: 'NO',
    },
    {
        name: 'Oman',
        dial_code: '+968',
        code: 'OM',
    },
    {
        name: 'Pakistan',
        dial_code: '+92',
        code: 'PK',
    },
    {
        name: 'Palau',
        dial_code: '+680',
        code: 'PW',
    },
    {
        name: 'Palestinian Territory, Occupied',
        dial_code: '+970',
        code: 'PS',
    },
    {
        name: 'Panama',
        dial_code: '+507',
        code: 'PA',
    },
    {
        name: 'Papua New Guinea',
        dial_code: '+675',
        code: 'PG',
    },
    {
        name: 'Paraguay',
        dial_code: '+595',
        code: 'PY',
    },
    {
        name: 'Peru',
        dial_code: '+51',
        code: 'PE',
    },
    {
        name: 'Philippines',
        dial_code: '+63',
        code: 'PH',
    },
    {
        name: 'Pitcairn',
        dial_code: '+872',
        code: 'PN',
    },
    {
        name: 'Poland',
        dial_code: '+48',
        code: 'PL',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
    },
    {
        name: 'Puerto Rico',
        dial_code: '+1939',
        code: 'PR',
    },
    {
        name: 'Qatar',
        dial_code: '+974',
        code: 'QA',
    },
    {
        name: 'Romania',
        dial_code: '+40',
        code: 'RO',
    },
    {
        name: 'Russia',
        dial_code: '+7',
        code: 'RU',
    },
    {
        name: 'Rwanda',
        dial_code: '+250',
        code: 'RW',
    },
    {
        name: 'Reunion',
        dial_code: '+262',
        code: 'RE',
    },
    {
        name: 'Saint Barthelemy',
        dial_code: '+590',
        code: 'BL',
    },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        dial_code: '+290',
        code: 'SH',
    },
    {
        name: 'Saint Kitts and Nevis',
        dial_code: '+1869',
        code: 'KN',
    },
    {
        name: 'Saint Lucia',
        dial_code: '+1758',
        code: 'LC',
    },
    {
        name: 'Saint Martin',
        dial_code: '+590',
        code: 'MF',
    },
    {
        name: 'Saint Pierre and Miquelon',
        dial_code: '+508',
        code: 'PM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        dial_code: '+1784',
        code: 'VC',
    },
    {
        name: 'Samoa',
        dial_code: '+685',
        code: 'WS',
    },
    {
        name: 'San Marino',
        dial_code: '+378',
        code: 'SM',
    },
    {
        name: 'Sao Tome and Principe',
        dial_code: '+239',
        code: 'ST',
    },
    {
        name: 'Saudi Arabia',
        dial_code: '+966',
        code: 'SA',
    },
    {
        name: 'Senegal',
        dial_code: '+221',
        code: 'SN',
    },
    {
        name: 'Serbia',
        dial_code: '+381',
        code: 'RS',
    },
    {
        name: 'Seychelles',
        dial_code: '+248',
        code: 'SC',
    },
    {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: 'SL',
    },
    {
        name: 'Singapore',
        dial_code: '+65',
        code: 'SG',
    },
    {
        name: 'Slovakia',
        dial_code: '+421',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        dial_code: '+386',
        code: 'SI',
    },
    {
        name: 'Solomon Islands',
        dial_code: '+677',
        code: 'SB',
    },
    {
        name: 'Somalia',
        dial_code: '+252',
        code: 'SO',
    },
    {
        name: 'South Africa',
        dial_code: '+27',
        code: 'ZA',
    },
    {
        name: 'South Sudan',
        dial_code: '+211',
        code: 'SS',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        dial_code: '+500',
        code: 'GS',
    },
    {
        name: 'Spain',
        dial_code: '+34',
        code: 'ES',
    },
    {
        name: 'Sri Lanka',
        dial_code: '+94',
        code: 'LK',
    },
    {
        name: 'Sudan',
        dial_code: '+249',
        code: 'SD',
    },
    {
        name: 'Suriname',
        dial_code: '+597',
        code: 'SR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        dial_code: '+47',
        code: 'SJ',
    },
    {
        name: 'Swaziland',
        dial_code: '+268',
        code: 'SZ',
    },
    {
        name: 'Sweden',
        dial_code: '+46',
        code: 'SE',
    },
    {
        name: 'Switzerland',
        dial_code: '+41',
        code: 'CH',
    },
    {
        name: 'Syrian Arab Republic',
        dial_code: '+963',
        code: 'SY',
    },
    {
        name: 'Taiwan',
        dial_code: '+886',
        code: 'TW',
    },
    {
        name: 'Tajikistan',
        dial_code: '+992',
        code: 'TJ',
    },
    {
        name: 'Tanzania, United Republic of Tanzania',
        dial_code: '+255',
        code: 'TZ',
    },
    {
        name: 'Thailand',
        dial_code: '+66',
        code: 'TH',
    },
    {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: 'TL',
    },
    {
        name: 'Togo',
        dial_code: '+228',
        code: 'TG',
    },
    {
        name: 'Tokelau',
        dial_code: '+690',
        code: 'TK',
    },
    {
        name: 'Tonga',
        dial_code: '+676',
        code: 'TO',
    },
    {
        name: 'Trinidad and Tobago',
        dial_code: '+1868',
        code: 'TT',
    },
    {
        name: 'Tunisia',
        dial_code: '+216',
        code: 'TN',
    },
    {
        name: 'Turkey',
        dial_code: '+90',
        code: 'TR',
    },
    {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: 'TM',
    },
    {
        name: 'Turks and Caicos Islands',
        dial_code: '+1649',
        code: 'TC',
    },
    {
        name: 'Tuvalu',
        dial_code: '+688',
        code: 'TV',
    },
    {
        name: 'Uganda',
        dial_code: '+256',
        code: 'UG',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
    },
    {
        name: 'United Arab Emirates',
        dial_code: '+971',
        code: 'AE',
    },
    {
        name: 'United Kingdom',
        dial_code: '+44',
        code: 'GB',
    },
    {
        name: 'United States',
        dial_code: '+1',
        code: 'US',
    },
    {
        name: 'Uruguay',
        dial_code: '+598',
        code: 'UY',
    },
    {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: 'UZ',
    },
    {
        name: 'Vanuatu',
        dial_code: '+678',
        code: 'VU',
    },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        dial_code: '+58',
        code: 'VE',
    },
    {
        name: 'Vietnam',
        dial_code: '+84',
        code: 'VN',
    },
    {
        name: 'Virgin Islands, British',
        dial_code: '+1284',
        code: 'VG',
    },
    {
        name: 'Virgin Islands, U.S.',
        dial_code: '+1340',
        code: 'VI',
    },
    {
        name: 'Wallis and Futuna',
        dial_code: '+681',
        code: 'WF',
    },
    {
        name: 'Yemen',
        dial_code: '+967',
        code: 'YE',
    },
    {
        name: 'Zambia',
        dial_code: '+260',
        code: 'ZM',
    },
    {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: 'ZW',
    },
];

export const LOCALES = [
    {
        label: 'Danish (Denmark)',
        value: 'da-DK',
    },
    {
        label: 'Arabic (Saudi Arabia)',
        value: 'ar-SA',
    },
    {
        label: 'Bangla (Bangladesh)',
        value: 'bn-BD',
    },
    {
        label: 'Bangla (India)',
        value: 'bn-IN',
    },
    {
        label: 'Czech (Czech Republic)',
        value: 'cs-CZ',
    },
    {
        label: 'Austrian German',
        value: 'de-AT',
    },
    {
        label: '"Swiss" German',
        value: 'de-CH',
    },
    {
        label: 'Standard German',
        value: 'de-DE',
    },
    {
        label: 'Modern Greek',
        value: 'el-GR',
    },
    {
        label: 'Australian English',
        value: 'en-AU',
    },
    {
        label: 'Canadian English',
        value: 'en-CA',
    },
    {
        label: 'British English',
        value: 'en-GB',
    },
    {
        label: 'Irish English',
        value: 'en-IE',
    },
    {
        label: 'Indian English',
        value: 'en-IN',
    },
    {
        label: 'New Zealand English',
        value: 'en-NZ',
    },
    {
        label: 'US English',
        value: 'en-US',
    },
    {
        label: 'English (South Africa)',
        value: 'en-ZA',
    },
    {
        label: 'Argentine Spanish',
        value: 'es-AR',
    },
    {
        label: 'Chilean Spanish',
        value: 'es-CL',
    },
    {
        label: 'Colombian Spanish',
        value: 'es-CO',
    },
    {
        label: 'Castilian Spanish ',
        value: 'es-ES',
    },
    {
        label: 'Mexican Spanish',
        value: 'es-MX',
    },
    {
        label: 'American Spanish',
        value: 'es-US',
    },
    {
        label: 'Finnish (Finland)',
        value: 'fi-FI',
    },
    {
        label: 'Belgian French',
        value: 'fr-BE',
    },
    {
        label: 'Canadian French',
        value: 'fr-CA',
    },
    {
        label: '"Swiss" French',
        value: 'fr-CH',
    },
    {
        label: 'Standard French ',
        value: 'fr-FR',
    },
    {
        label: 'Hebrew (Israel)',
        value: 'he-IL',
    },
    {
        label: 'Hindi (India)',
        value: 'hi-IN',
    },
    {
        label: 'Hungarian (Hungary)',
        value: 'hu-HU',
    },
    {
        label: 'Indonesian (Indonesia)',
        value: 'id-ID',
    },
    {
        label: '"Swiss" Italian',
        value: 'it-CH',
    },
    {
        label: 'Standard Italian',
        value: 'it-IT',
    },
    {
        label: 'Japanese (Japan)',
        value: 'ja-JP',
    },
    {
        label: 'Korean (Republic of Korea)',
        value: 'ko-KR',
    },
    {
        label: 'Belgian Dutch',
        value: 'nl-BE',
    },
    {
        label: 'Standard Dutch',
        value: 'nl-NL',
    },
    {
        label: 'Norwegian (Norway)',
        value: 'no-NO',
    },
    {
        label: 'Polish (Poland)',
        value: 'pl-PL',
    },
    {
        label: 'Brazilian Portuguese',
        value: 'pt-BR',
    },
    {
        label: 'European Portuguese',
        value: 'pt-PT',
    },
    {
        label: 'Romanian (Romania)',
        value: 'ro-RO',
    },
    {
        label: 'Russian (Russian Federation)',
        value: 'ru-RU',
    },
    {
        label: 'Slovak (Slovakia)',
        value: 'sk-SK',
    },
    {
        label: 'Swedish (Sweden)',
        value: 'sv-SE',
    },
    {
        label: 'Indian Tamil',
        value: 'ta-IN',
    },
    {
        label: 'Sri Lankan Tamil',
        value: 'ta-LK',
    },
    {
        label: 'Thai (Thailand)',
        value: 'th-TH',
    },
    {
        label: 'Turkish (Turkey)',
        value: 'tr-TR',
    },
    {
        label: 'Mainland China, simplified characters',
        value: 'zh-CN',
    },
    {
        label: 'Hong Kong, traditional characters',
        value: 'zh-HK',
    },
    {
        label: 'Taiwan, traditional characters',
        value: 'zh-TW',
    },
];

export const MONTHS = [
    {
        label: 'Jan',
        value: '01',
    },
    {
        label: 'Feb',
        value: '02',
    },
    {
        label: 'Mar',
        value: '03',
    },
    {
        label: 'Apr',
        value: '04',
    },
    {
        label: 'May',
        value: '05',
    },
    {
        label: 'Jun',
        value: '06',
    },
    {
        label: 'Jul',
        value: '07',
    },
    {
        label: 'Aug',
        value: '08',
    },
    {
        label: 'Sep',
        value: '09',
    },
    {
        label: 'Oct',
        value: '10',
    },
    {
        label: 'Nov',
        value: '11',
    },
    {
        label: 'Dec',
        value: '12',
    },
];
export const LICENSE_DETAILS_URL = `api/edge/secrets/`;
export const BID_STATUS_API_URL = `api/edge/bid-status/`;

export const API_KEY_ACCESS = {
    device: true,
    license: false,
    bid: false,
    frequency: false,
    edge: false,
    signal: false,
    datahub: false,
    monitoring: false,
    events: false,
    notification: false,
    upload: false,
};

export const SNOOZE_MINUTES = [
    {
        label: '30 Minutes',
        value: 30,
    },
    {
        label: '60 Minutes',
        value: 60,
    },
    {
        label: '90 Minutes',
        value: 90,
    },
    {
        label: '24 Hours',
        value: 1440,
    },
    {
        label: '2 Days',
        value: 2880,
    },
    {
        label: '3 Days',
        value: 4320,
    },
    {
        label: 'Cancel Snooze',
        value: -1,
    },
];

export const FREQUENCY_VALUES = {
    FCR: {
        0: 49.8,
        10: 49.818,
        20: 49.836,
        30: 49.854,
        40: 49.872,
        50: 49.89,
        60: 49.908,
        70: 49.926,
        80: 49.944,
        90: 49.962,
        100: 49.98,
        '100a': 50.02,
        110: 50.038,
        120: 50.056,
        130: 50.074,
        140: 50.092,
        150: 50.11,
        160: 50.128,
        170: 50.146,
        180: 50.164,
        190: 50.182,
        200: 50.2,
    },
    'FCR-D-INC': {
        '100a': 50.1,
        110: 50.12,
        120: 50.16,
        130: 50.2,
        140: 50.24,
        150: 50.28,
        160: 50.32,
        170: 50.36,
        180: 50.4,
        190: 50.44,
        200: 50.48,
    },
    'FCR-D-DEC': {
        0: 49.52,
        10: 49.56,
        20: 49.6,
        30: 49.64,
        40: 49.68,
        50: 49.72,
        60: 49.76,
        70: 49.8,
        80: 49.84,
        90: 49.88,
        100: 49.9,
    },
};
export const FREQUENCY_DIRECTION = [
    { label: 'All', value: 'All' },
    { label: 'Increased', value: 'increased' },
    { label: 'Decreased', value: 'decreased' },
];

export const PERCENTAGE_DROPDOWN = [
    { label: '0', value: '0' },
    { label: 20, value: 20 },
    { label: 40, value: 40 },
    { label: 60, value: 60 },
    { label: 80, value: 80 },
    { label: 100, value: 100 },
];
