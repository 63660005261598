import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import moment from 'moment-timezone';
import { LogsService } from '../../../../services/LogsService';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../hooks/use-loader.hook';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { UilInfoCircle } from '@iconscout/react-unicons';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';

const TriggerLogsTable = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [triggerType, setTriggerType] = useState({});
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState('');

    useEffect(() => {
        fetchData();
    }, [skip, limit, triggerType]);

    const fetchData = () => {
        if (triggerType.value) {
            let params = {
                limit,
                skip,
                triggerType: triggerType.value,
            };

            LogsService.Trigger(params, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data.data.map((item, index) => {
            return {
                ...item,
                timestamps: item?.timestamps && moment(item?.timestamps).format('MMMM Do YYYY, h:mm:ss a'),
                action:
                    item?.triggerType === 'slack' ? (
                        <div
                            onClick={() => {
                                setModalData(JSON.stringify(item?.properties?.body, null, 4));
                                setOpen(true);
                            }}
                        >
                            <CustomTooltip content={'Info'}>
                                <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </CustomTooltip>
                        </div>
                    ) : (
                        // <CustomTooltip content={item?.properties.body || "Message is empty"}>
                        <CustomTooltip
                            content={
                                typeof item?.properties?.body === 'string'
                                    ? item?.properties?.body
                                    : item?.properties?.body?.message
                                      ? item?.properties?.body?.message
                                      : 'Message is empty'
                            }
                        >
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CustomTooltip>
                    ),
                subType: item?.properties?.type || '--',
                source: item?.properties?.source || '--',
                contact: item?.properties?.contact || '--',
            };
        });
        setTotalCount(data.data.count);
        setData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content={'Trigger Logs'} />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount} />
                        </span>
                    </div>
                </div>
                <div>
                    <DropdownComponent
                        defaultValue={queryParamsData.type ? JSON.parse(queryParamsData.type) : { value: 'slack', label: 'Slack' }}
                        options={[
                            { value: 'slack', label: 'Slack' },
                            { value: 'email', label: 'Email' },
                            { value: 'sms', label: 'SMS' },
                        ]}
                        onChange={(e) => {
                            setTriggerType(e);
                            updateQueryParams('type', JSON.stringify(e));
                        }}
                    />
                </div>
            </div>
            {open && (
                <ModalComponent
                    isOpen={open}
                    setOpen={setOpen}
                    onClose={() => setOpen(false)}
                    style={{
                        maxWidth: '60vw',
                        maxHeight: '70vh',
                        overflowY: 'scroll',
                    }}
                >
                    <h3>Message Body:</h3>
                    <p>{modalData}</p>
                    <div className={classes.ButtonBottomRight}>
                        <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                            Cancel
                        </button>
                    </div>
                </ModalComponent>
            )}
            <div>
                <Table
                    head={['Trigger Type', 'Sub Type', 'Source', 'Contact', 'Success', 'Triggered At', 'Action']}
                    keys={['triggerType', 'subType', 'source', 'contact', 'response.success', 'timestamps', 'action']}
                    data={data}
                    page={page}
                    Pagination={true}
                    limit={limit}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default TriggerLogsTable;
