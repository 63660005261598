// Standard Library Imports
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// External Library Imports
import { UilCopy, UilExclamationOctagon, UilRedo } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';

// Internal Module Imports
import Typography from '../../../components/Typography/Typography';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLoader } from '../../../hooks';
import { ApikeyService } from '../../../services/apiKeyService';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import TabsComponent from '../../../components/Tabs/Tabs';
import SystemInfo from './systemInfo';
import { SystemServices } from '../../../services/SystemServices';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

// CSS Imports
import classes from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';
import Table from '../../../components/Table/Table';
import { Capitalize } from '../../../utils/stringHelper';

const EdgeInfo = ({ infoModal, setInfoModal, selectCustomer, rotateMQTTPassword, rotateAPIKey }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [systemInfo, setSystemInfo] = useState({});
    const [rotateMQTTPasswordModal, setRotateMQTTPasswordModal] = useState({ status: false, edgeId: '' });
    const [rotateAPIKeyModal, setRotateAPIKeyModal] = useState({ status: false, edgeId: '' });

    useEffect(() => {
        const tempData = [
            {
                name: 'ECR Repository URI',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.ecrRepositoryName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.data} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Customer Id',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.customerId}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.customerId} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Instance Id',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.instanceId || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.instanceId} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'License Code',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.licenseCode || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.licenseCode} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'API Key',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.apiKey}</div>
                    </div>
                ),
                action: (
                    <div>
                        {infoModal.apiKey ? (
                            <div
                                style={
                                    !infoModal?.access.device || !infoModal?.access.bid || !infoModal?.access.license || !infoModal?.access.edge || infoModal.apiKey
                                        ? {
                                            display: 'flex',
                                            gap: '0.5vw',
                                        }
                                        : {}
                                }
                            >
                                <UilRedo size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setRotateAPIKeyModal({ status: true, edgeId: infoModal.edgeId })} />
                                {!infoModal?.access.device || !infoModal?.access.bid || !infoModal?.access.license || !infoModal?.access.edge ? (
                                    <CustomTooltip
                                        content={
                                            <div>
                                                {Object.keys(infoModal?.access).map((key) => (
                                                    <div style={{ display: 'flex', gap: '0.3vw' }}>
                                                        <span> {Capitalize(key)}:</span>
                                                        <span> {infoModal?.access[key] === true ? 'Enabled' : 'Disabled'}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <UilExclamationOctagon size={'1.2vw'} style={{ color: 'red' }} />
                                    </CustomTooltip>
                                ) : (
                                    <div></div>
                                )}
                                <CopyToClipboard text={infoModal.apiKey} onCopy={() => toast.success('Copied!')}>
                                    <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CopyToClipboard>
                            </div>
                        ) : (
                            <button className="btn-primary" onClick={generateApiKey} style={{ marginTop: 0 }}>
                                Generate API Key
                            </button>
                        )}
                    </div>
                ),
            },
            {
                name: 'Secrets API',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.licenseDetailUrl}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard
                            text={`${infoModal.licenseDetailUrl}?apikey=${infoModal?.apiKey || '{api-key}'}&edgeId=${infoModal.edgeId}`}
                            onCopy={() => toast.success('Copied!')}
                        >
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Bid Status API',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.bidStatusApiUrl}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard
                            text={`${infoModal.bidStatusApiUrl}?apikey=${infoModal?.apiKey || '{api-key}'}&edgeId=${infoModal.edgeId}`}
                            onCopy={() => toast.success('Copied!')}
                        >
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MQTT User Name',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.userName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.userName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MQTT Password',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.password || '--'}</div>
                    </div>
                ),
                action: (
                    <div className={EdgeClasses.EdgeInfoIconContainer}>
                        <div onClick={() => setRotateMQTTPasswordModal({ status: true, edgeId: infoModal.edgeId })}>
                            <UilRedo size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                        <div>
                            <CopyToClipboard text={infoModal.password} onCopy={() => toast.success('Copied!')}>
                                <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </CopyToClipboard>
                        </div>
                    </div>
                ),
            },
            {
                name: 'Created At (CET)',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{infoModal.createdAt || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.createdAt} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setData(tempData);
        if (infoModal?.systemId && infoModal.status) {
            getSystemInfo();
        }
    }, []);

    const generateApiKey = () => {
        let payload = {
            data: {
                customerId: selectCustomer?.value,
                isActive: true,
                name: `${selectCustomer?.label} (${infoModal.edgeAlias})- API Key`,
                access: {
                    bid: true,
                    license: true,
                    device: true,
                    edge: true,
                },
                edgeId: infoModal.edgeId
            },
        };
        ApikeyService.Create(payload, startLoader, handleGenerateApikeySuccess, handleError, stopLoader);
    };

    const handleGenerateApikeySuccess = ({ data }) => {
        toast.success('API Key Created!');
        setInfoModal({
            ...infoModal,
            apiKey: data?.data?.key,
            access: {
                device: data?.data?.access?.device || false,
                license: data?.data?.access?.license || false,
                bid: data?.data?.access?.bid || false,
                edge: data?.data?.access?.edge || false,
            },
        });
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleDownloadExcel = () => {
        const processedData = [
            {
                ecrRepositoryUri: infoModal?.ecrRepositoryUri || '--',
                customerId: infoModal?.customerId,
                instanceId: infoModal?.instanceId || '--',
                apiKey: infoModal?.apiKey || '--',
                licenseDetailUrl: infoModal?.licenseDetailUrl || '--',
                bidStatusApiUrl: infoModal?.bidStatusApiUrl || '--',
                awsAccessKey: infoModal?.awsAccessKey || '--',
                awsSecretKey: infoModal?.awsSecretKey || '--',
                userName: infoModal.userName || '--',
                password: infoModal.password || '--',
            },
        ];

        DownloadAsExcel(processedData, `Edge Info`, [
            'ECR Repository URI',
            'Customer ID',
            'Instance ID',
            'API Key',
            'License Details API',
            'Bid Status API',
            'AWS Access Key',
            'AWS Secret Key',
            'MQTT User Name',
            'MQTT Password',
        ]);
    };

    const getSystemInfo = () => {
        SystemServices.getSystemInfo(infoModal?.systemId, startLoader, handleGetSystemInfoSuccess, handleError, stopLoader);
    };

    const handleGetSystemInfoSuccess = ({ data }) => {
        setSystemInfo(data?.data[0].system);
        const awsInfo = [
            {
                name: 'AWS Access Key',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.data[0]?.awsDoc?.[0]?.awsAccessKey || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.data[0]?.awsDoc?.[0]?.awsAccessKey} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'AWS Secret Key',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.data[0]?.awsDoc?.[0]?.awsSecretKey || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.data[0]?.awsDoc?.[0]?.awsSecretKey} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setData((prevData) => [...prevData, ...awsInfo]);
    };

    const tabs = [
        {
            name: 'Edge Info',
            component: (
                <div style={{ minWidth: '40vw', overflow: 'auto', height: '70vh' }}>
                    <div className={EdgeClasses.EdgeInfoModalHeader}>
                        <Typography content={'Edge Info'} />
                        <div>
                            {' '}
                            <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />
                        </div>
                    </div>
                    <div>
                        <Table head={['Label', 'Value', 'Action']} keys={['name', 'value', 'action']} data={data} />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setInfoModal({ ...infoModal, status: false })}>
                                Close
                            </button>
                        </div>
                    </div>
                    <DeleteModal
                        deletefunction={rotateMQTTPassword}
                        opendeleteModal={rotateMQTTPasswordModal.status}
                        setOpenDeleteModal={(status) => setRotateMQTTPasswordModal({ edgeId: '', status })}
                        deviceId={rotateMQTTPasswordModal.edgeId}
                        title="Rotate MQTT Credentials"
                        text="Are You Sure. Your Want To Generate New MQTT Credentials?"
                    ></DeleteModal>
                    <DeleteModal
                        deletefunction={rotateAPIKey}
                        opendeleteModal={rotateAPIKeyModal.status}
                        setOpenDeleteModal={(status) => setRotateAPIKeyModal({ edgeId: '', status })}
                        deviceId={rotateAPIKeyModal.edgeId}
                        title="Rotate Edge API key"
                        text="Are You Sure. Your Want To Generate New API Key?"
                    ></DeleteModal>
                </div>
            ),
        },
        {
            name: 'System Info',
            component: infoModal?.systemId ? (
                <SystemInfo data={systemInfo} setOpen={setInfoModal} />
            ) : (
                <div>
                    <p>No system configured.</p>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setInfoModal({ ...infoModal, status: false })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default EdgeInfo;
